import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Projects = ({ data }) => {
  // Animation variants for staggering the appearance
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.4,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -100 },
    show: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  return (
    <div className="projects">
      <div className="projects-title">Projects</div>
      <motion.div
        className="project-div"
        variants={containerVariants}
        initial="hidden"
        animate="show"
      >
        {data?.projects?.map((job, index) => (
          <motion.div key={index} className="job" variants={itemVariants}>
            <h3>{job.title}</h3>
            <p className="company-name">{job.company + " - " + job.date}</p>
            {job?.projects &&
              job?.projects.map((project, i) => (
                <div key={i} className="project">
                  <Link
                    to={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="project-links"
                  >
                    {project.name}
                  </Link>
                  <p className="client-name">{project.customer}</p>
                </div>
              ))}
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default Projects;
