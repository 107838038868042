import React from "react";
import { motion } from "framer-motion";

const About = ({ data }) => {
  // Animation variants for staggering the appearance
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.4,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    show: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };
  return (
    <div className="about-me">
      <>
        <span>Get To Know More</span>
        <div className="aboutme-header">About Me</div>
      </>
      <motion.div
        className="about-div"
        variants={containerVariants}
        initial="hidden"
        animate="show"
      >
        <motion.div className="about-me-div" variants={itemVariants}>
          <span className="experience-header">Experience</span>
          {data?.experience?.map((ex) => (
            <p key={ex}>{ex}</p>
          ))}
        </motion.div>
        <motion.div className="about-me-div" variants={itemVariants}>
          <span className="education-header">Education</span>
          <br />
          <div>
            <b>{data?.education?.degree}</b>,{" "}
            <span>{data?.education?.date}</span>
          </div>
          <span>
            {data?.education?.school}, {data?.education?.location}
          </span>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default About;
