import React from "react";
import { MdMarkEmailUnread } from "react-icons/md";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';


const Contact = ({ data }) => {
  const { name, email, linkedIn } = data;
   // Animation variants for staggering the appearance
   const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.4,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -100 },
    show: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };
  return (
    <div className="contact">
      <>
        <span>Get In Touch</span>
        <div className="contact-header">Contact Me</div>
      </>
      <motion.div
        className="ttext-end"
        variants={containerVariants}
        initial="hidden"
        animate="show"
      >
      {email && (
        <motion.a href={`mailto:${email}`} className="contact-link" variants={itemVariants}>
          <MdMarkEmailUnread />&nbsp;{email}
        </motion.a>
      )}

      {linkedIn && (
        <motion.a href="https://in.linkedin.com/in/pramodmorthala" target="_blank" rel="noopener noreferrer" className="contact-link" variants={itemVariants}>
          <FaLinkedinIn />&nbsp;{linkedIn}
        </motion.a>
      )}
      </motion.div>
    </div>
  );
};

export default Contact;
