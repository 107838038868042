import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  FaReact,
  FaHtml5,
  FaCss3,
  FaBootstrap,
  FaGitAlt,
  FaNpm,
  FaJira,
  FaWindows,
  FaLinux,
} from "react-icons/fa";
import { AiOutlineJavaScript, AiOutlineCode } from "react-icons/ai";
import { RiGatsbyLine, RiNextjsLine } from "react-icons/ri";
import { VscVscodeInsiders } from "react-icons/vsc";
import { SiMysql, SiRedux, SiGoogletagmanager, SiGoogleanalytics,SiTypescript } from "react-icons/si";
import { GrGraphQl } from "react-icons/gr";
import { IoLogoPwa } from "react-icons/io5";

//import "./Skills.css"; // Ensure you have the CSS styles in a separate file

const iconSize = '50px';
const Skills = () => {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    axios.get("/assets/myData.json").then((response) => {
      setSkills(response.data.skills);
    });
  }, []);

  const getSkillImage = (skill) => {
    try {
      return require(`../assets/icons/${skill}.png`);
    } catch (err) {
      return require(`../assets/icons/default.png`); 
    }
  };

   

  return (
    <div className="skills">
      <div className="skills-title mb-lg-5 mb-md-2">Skills</div>
      <div className="marquee">
        <div className="marquee-content">
          {skills.map((skill, index) => (
            <div key={index}>
            <div  className="skill-item" title={skill}>
            <img src={getSkillImage(skill)} alt={skill} style={{ width: iconSize, height: iconSize }} />
            </div>
            <div className="skill-name">{skill}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
