import React from 'react'
import OopsImage from '../assets/404.png';
import { Link } from 'react-router-dom';

const Oops = () => {
  return (
    <div className='d-flex justify-content-end  align-items-end h-100 flex-column w-100'>
        <img src={OopsImage} alt="oops" className='oops-image' />
        <Link to="/" className='btn btn-outline-dark go-back-btn'>Go To Home</Link>
    </div>
  )
}

export default Oops