import React, { useEffect, useState } from 'react';
import axios from 'axios';
import myPic from '../assets/pramod1.jpg'

const Home = ({data}) => {
  
  return (
    <div className="summary comp-global">
    <div className='profile'>
      <div className='greet mb-2'>Hello, I'm a</div>
      <h2 className='name mb-2'>Frontend Developer</h2>
      
      <div className='profile-div'>
      {/* <div className='circle-container'>
        <div className='circle-text'>
          <span>HTML</span>
          <span>CSS</span>
          <span>ReactJs</span>
          <span>TS</span>
          <span>NextJs</span>
          <span>JS</span>
          <span>GatsbyJs</span>
          <span>CMS</span>
          <span>Tailwind</span>
          <span>GA4</span>
          <span>Git</span>
          <span>Bootstrap</span>
          <span>NPM</span>
          <span>JIRA</span>
          <span>NodeJs</span>
        </div>
      </div> */}
        <img src={myPic} alt='Pramod Morthala' className='profile-pic mb-3'/>
      </div>

      {/* <div className='desig mb-2'>Frontend Developer</div> */}
      <a href='/assets/pramodmorthala.pdf' download className='btn download-cv'>Download CV</a>
    </div>
  </div>
  );
};

export default Home;
