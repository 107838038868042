import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Home from './components/Home';
import Skills from './components/Skills';
import axios from 'axios';
import About from './components/About';
import Contact from './components/Contact';
import Projects from './components/Projects';
import Oops from './components/404';
import BG from './assets/circle.png'
import MandalaSVG from './components/Mandala';


const App = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    axios.get('/assets/myData.json').then((response) => {
      setData(response.data);
    });
  }, []);
  return (
    <Router>
        <Header data={data}/>
      <div className="App container-fluid">
       <MandalaSVG/>
        <Routes>
          <Route path="/" element={<Home data={data} />} />
          <Route path="/about" element={<About data={data} />} />
          <Route path="/skills" element={<Skills data={data} />} />
          <Route path="/projects" element={<Projects data={data}/>}/>
          <Route path="/contact" element={<Contact data={data}/>} />
          <Route path="*" element={<Oops/>}/>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
